<template>
  <div class="progressv2-bg2">
    <div class="progressv2">
      <span class="current-time">{{ formattedCurrentTime }}</span>
      <div class="progressv2-bar" @click="handleProgressClick">
        <div class="progressv2-background"></div>
        <div class="progressv2-fill" :style="{ width: progressWidth }"></div>
        <div class="progressv2-dot" :style="{ left: progressWidth }"></div>

        <!-- tag dot -->
        <div
          class="progressv2-oneminute-dot"
          :style="{ left: oneMinuteTagDotLeft }"
        ></div>
      </div>
      <span class="duration">{{ formattedDuration }}</span>
    </div>
  </div>
</template>

<script>
import { computed, defineEmits } from 'vue'

export default {
  props: {
    currentTime: {
      type: Number,
      default: 0,
    },
    duration: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const emit = defineEmits(['update:currentTime'])

    const progressWidth = computed(() => {
      return `${(props.currentTime / props.duration) * 100}%`
    })

    const formatTime = (time) => {
      const minutes = Math.floor(time / 60)
      const seconds = Math.floor(time % 60)
      return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
        2,
        '0'
      )}`
    }

    // 计算属性用于格式化时间
    const formattedCurrentTime = computed(() => formatTime(props.currentTime))
    const formattedDuration = computed(() => formatTime(props.duration))

    const handleProgressClick = (event) => {
      const rect = event.target.getBoundingClientRect()
      const offsetX = event.clientX - rect.left
      const width = rect.width
      const newTime = (offsetX / width) * props.duration
      // emit('update:currentTime', newTime)
      console.log('newTime:', newTime)
    }

    const oneMinuteTagDotLeft = computed(() => {
      return `${(60 / props.duration) * 100}%`
    })

    return {
      progressWidth,
      oneMinuteTagDotLeft,
      formattedCurrentTime,
      formattedDuration,
      handleProgressClick,
    }
  },
}
</script>

<style lang="stylus" scoped>
.progressv2-bg2
  position fixed
  left 0
  bottom 0
  width 100vw
  height 150px
  z-index 9!important
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)
  @media screen and (max-width 1200px) and (min-height 1200px)
    bottom 960px!important

  .progressv2
    position absolute
    bottom 36px
    left 60px
    right 60px
    display flex
    z-index 10
    align-items center
    justify-content space-between
    color white
    font-size: 32px
    font-weight: 400
    @media screen and (max-width 1200px) and (min-height 1200px)
      left 20px
      right 20px
    span
      display inline-block
      width 130px
      text-align center

  .progressv2-bar
    position relative
    flex-grow 1
    height 8px
    background-color black
    margin 0 30px
    cursor pointer
    border-radius 2px

  .progressv2-background
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    background-color black

  .progressv2-fill
    position absolute
    top 0
    left 0
    height 100%
    background-color white
    transition width 0.2s ease

  .progressv2-dot
    position absolute
    top 50%
    transform translate(-50%, -50%)
    width 18px
    height 20px
    background-color white
    border-radius 4px
    transition left 0.2s ease
  .progressv2-oneminute-dot
    position absolute
    top 50%
    transform translate(-50%, -50%)
    width 18px
    height 20px
    background-color rgba(255, 255, 255, 0.5)
    border-radius 4px
    transition left 0.2s ease
</style>
