<template>
  <div class="page album">
    <div class="album-content">
      <div class="album-content-left">
        <Mv
          class="album-content-left-top"
          :song="song"
          :isPlaying="isPlaying"
          :isFullScreen="isFullScreen"
          @controlMvScreen="handleControlMvScreen"
          @controlPlay="handleControlPlay"
          @controlPause="handleControlPause"
          @controlCanPlay="handleControlCanPlay"
          @controlEnded="handleControlEnded"
          @controlPre="handleControlPre"
        />
      </div>
    </div>
  </div>
</template>

<script>
import useLoading from '@/composables/useLoading'
import { getTrySongM3U8 } from '@/service/song'
import eventBus from '@/utils/event-bus'
import Toast from '@/utils/toast'
import get from 'lodash/get'
import { computed, onBeforeMount, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import Mv from './components/mv/index.vue'

export default {
  name: 'Album',
  components: {
    Mv,
  },
  setup() {
    const store = useStore()
    const { showLoading, hideLoading } = useLoading()
    const dataList = ref([])
    const song = ref({})

    let isPlaying = ref(true)
    let isFullScreen = ref(true)

    const isLogin = computed(() => !!store.state.userInfo.unionid)
    const isVip = computed(() => !!store.state.vipInfo.end_time)
    const userType = computed(() => store.state.userInfo.userType)
    const vipAuditionSong = computed(() => store.state.vipAuditionSong)

    const handleControlMvScreen = () => {
      console.log('handleControlMvScreen')
      isFullScreen.value = !isFullScreen.value
    }

    const handleControlPre = () => {}

    const handleControlPlay = (func) => {
      console.log('handleControlPlay')
      isPlaying.value = true
      func && func()
    }

    const handleControlPause = (func) => {
      console.log('handleControlPause')
      isPlaying.value = false
      func && func()
    }

    const handleControlCanPlay = () => {
      console.log('handleControlCanPlay')
      hideLoading()
      isPlaying.value = true
    }

    const handleControlEnded = () => {
      console.log('handleControlEnded')
    }

    onBeforeMount(async () => {
      eventBus.emit('handle-video-muted')
      // eventBus.emit('video-control-pause')
      // setTimeout(() => {
      //   eventBus.emit('handle-video-play');
      // }, 2000);
    })

    onMounted(async () => {
      console.log('onMounted vipAuditionSong', vipAuditionSong.value)
      song.value = vipAuditionSong.value
      Toast({
        message: 'VIP歌曲试唱中，完整版请开通VIP',
        duration: 6000,
        className: 'toast-zoom-max',
      })
    })

    watch(song, async (newVal, oldVal) => {
      if (newVal.songid == oldVal.songid) return
      // console.log('watch newVal', newVal)
      const res = await getTrySongM3U8(newVal.songid)
      if (!get(res, 'resource_url', '')) {
        Toast('暂无该歌曲资源～')
        return
      }

      // showLoading()

      // 更新歌曲信息
      song.value = {
        ...song.value,
        video_url: get(res, 'resource_url', ''),
        token: get(res, 'token', ''),
      }
      console.log(song.value)
    })

    onBeforeMount(async () => {
      eventBus.emit('video-control-pause')
    })

    return {
      dataList,
      isPlaying,
      song,
      isFullScreen,
      vipAuditionSong,
      handleControlMvScreen,
      handleControlPlay,
      handleControlPause,
      handleControlCanPlay,
      handleControlEnded,
      handleControlPre,
      isLogin,
      isVip,
      userType,
    }
  },
}
</script>

<style lang="stylus" scoped>
.album
  width 100%
  height 100vh
  position relative
  padding 0!important
  // background url(https://qncweb.ktvsky.com/20240902/vadd/d7dca9b1f59836e6dd0ac1b4dfa508b8.png) no-repeat
  // background-size 100% 100%
  // background-position center
  .album-bg-small
    width 100%
    height 100vh
    background url(https://qncweb.ktvsky.com/20240902/vadd/d7dca9b1f59836e6dd0ac1b4dfa508b8.png) no-repeat
    background-size 100% 100%
    background-position center
    position absolute
    top 0
    left 0
  .album-bg
    width 100%
    height 100vh
    // background url(https://qncweb.ktvsky.com/20240902/vadd/d7dca9b1f59836e6dd0ac1b4dfa508b8.png) no-repeat
    background-size 100% 100%
    background-position center
    position absolute
    top 0
    left 0
    opacity 0
    transition opacity 0.3s ease-in-out
  .album-content
    width 100%
    height calc(100vh - 132px)
    display flex
    flex-direction row
    .album-content-left
      width 1000px
      height calc(100vh - 132px)
      .album-content-left-top
        display flex
        width 1000px
        height 553px
        margin-bottom 32px
</style>
